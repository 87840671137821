import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
// import Form from "../components/contact-form";

const Page = (props) => {
    return <>
      <Layout {...props}>
          <SEO />
          Contact Page
      </Layout>
    </>
}

export default Page;
